/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Libraries
import Select from 'react-select'

// Elements
import { Input, Select as InputSelect, Button as ButtonDefault } from 'components/elements'
import CountUp from 'react-countup'
import GravityForm from '../shared/GravityForm'
import ParseContent from '../shared/ParseContent'

const StyledCalculator = styled.div`
  margin-top: 90px;
  background: white;
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  @media (min-width: 992px) {
    padding: 10px 100px 0px 100px;
  }

  @media (max-width: 991px) {
    padding: 10px 1.5rem 0px 1.5rem;
  }
`

const Title = styled.div`
  font-weight: ${(props) => props.theme.font.weight.l};
  line-height: ${(props) => props.theme.font.size.xxxl};
  color: ${(props) => props.theme.color.text.main};
  background: white;
  padding: 0 20px;
  position: relative;
  margin-top: -35px;
  width: fit-content;
  margin-bottom: 40px;

  @media (min-width: 992px) {
    font-size: ${(props) => props.theme.font.size.xl};
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.l};
  }

  @media (max-width: 575px) {
    font-size: ${(props) => props.theme.font.size.xm};
  }
`

const InputMoneyWrapper = styled.div`
  position: relative;
`

const InputMoneyWrapperValuta = styled.div`
  position: absolute;
  left: 12px;
  top: ${props => props.filled ? 24 : 16}px;
`

const InputMoney = styled(Input)`
  ${props => props.filled ? css`
    padding: 22px 19px 6px 36px;
  ` : css`
    padding: 14px 19px 14px 36px;
  `};
`

const Label = styled.div`
  font-size: ${(props) => props.theme.font.size.sm};
  font-weight: ${(props) => props.theme.font.weight.s};
  position: absolute; 
  top: 3px;
  left: 10px;
  z-index: 1;
  color: ${(props) => props.theme.color.text.main};
  opacity: .5;
`

/*
  START DATA
*/

const branches = [
  {
    label: 'Horeca',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Bouw',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Zorg',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'ICT',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Metaal & Techniek',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Land- en Tuinbouw',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Media & Comm',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Handel & Retail',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Juridische dienstverlening',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Transport & Logistiek',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 0.4 },
      { start: 16, end: 25, cost: 0.42 },
      { start: 26, end: 40, cost: 0.44 },
      { start: 41, end: 60, cost: 0.46 },
      { start: 61, end: 80, cost: 0.48 },
      { start: 81, end: 100, cost: 0.5 },
      { start: 101, end: 150, cost: 0.52 },
      { start: 151, end: 250, cost: 0.54 },
      { start: 251, end: 400, cost: 0.56 },
      { start: 401, end: 500, cost: 0.58 },
    ]
  },

  {
    label: 'Anders',
    tiersRevenuePerMonth: [
      { start: 0, end: 50000, percentage: 2.5 },
      { start: 50001, end: 75000, percentage: 2 },
      { start: 75001, end: 100000, percentage: 1.75 },
      { start: 100001, end: 150000, percentage: 1.3 },
      { start: 150001, end: 200000, percentage: 1.1 },
      { start: 200001, end: 250000, percentage: 1 },
      { start: 250001, end: 300000, percentage: 0.9 },
      { start: 300001, end: 500000, percentage: 0.7 },
      { start: 500001, end: 750000, percentage: 0.5 },
      { start: 750001, end: 1000000, percentage: 0.4 },
    ],
    tiersProcessPerMonth: [
      { start: 0, end: 15, cost: 4 },
      { start: 16, end: 25, cost: 3.75 },
      { start: 26, end: 40, cost: 3.5 },
      { start: 40, end: 60, cost: 3.25 },
      { start: 61, end: 80, cost: 3 },
      { start: 81, end: 100, cost: 2.75 },
      { start: 101, end: 150, cost: 2.5 },
      { start: 151, end: 250, cost: 2.25 },
      { start: 251, end: 400, cost: 2 },
      { start: 401, end: 500, cost: 1.75 },
    ]
  },
]
/*
  END DATA
*/

const Calculator = ({ description = '' }) => {
  const [percentage, setPercentage] = React.useState(false)
  const [fields, setFields] = React.useState({
    branche: '',
    revenuePerMonth: '',
    processPerMonth: '',
    currentCostPerYear: ''
  })

  const convertToNumber = (value) => {
    const newValue = value.replace(/\D+/g, '')

    return newValue
  }

  const getCurrentBranche = () => {
    return branches.find(({ label }) => label === fields.branche)
  }

  const currentBranche = getCurrentBranche()

  const figureOut = () => {
    if (!fields.branche) {
      return false
    }

    const currentFigureOutBranche = getCurrentBranche()

    if (!fields.revenuePerMonth) {
      return false
    }

    const tierRevenuePerMonth = currentFigureOutBranche.tiersRevenuePerMonth.find(tier => tier.start <= fields.revenuePerMonth && tier.end >= fields.revenuePerMonth)

    if (!fields.processPerMonth || !tierRevenuePerMonth) {
      return false
    }

    const tierProcessPerMonth = currentFigureOutBranche.tiersProcessPerMonth.find(tier => tier.start <= fields.processPerMonth && tier.end >= fields.processPerMonth)

    if (!tierProcessPerMonth) {
      return false
    }

    const processCostPerYear = (fields.processPerMonth * (tierProcessPerMonth.cost * 12))
    const revenueCostPerYear = ((fields.revenuePerMonth) / 100) * tierRevenuePerMonth.percentage
    const totalCostPerYear = processCostPerYear + revenueCostPerYear

    if (!fields.currentCostPerYear) {
      return false
    }

    const differenceInCost = fields.currentCostPerYear - totalCostPerYear
    const response = Number(100 - (((fields.currentCostPerYear - differenceInCost) / fields.currentCostPerYear) * 100)).toFixed(2)

    console.log(differenceInCost);

    return response
  }

  let revenuePerMonthOptions = []
  let processPerMonthOptions = []

  if (fields.branche) {
    revenuePerMonthOptions = currentBranche.tiersRevenuePerMonth.map(edge => ({ value: edge.end, label: `€${edge.start} - €${edge.end} p/ jaar` }))
    processPerMonthOptions = currentBranche.tiersProcessPerMonth.map(edge => ({ value: edge.end, label: `${edge.start} - ${edge.end} facturen p/ maand` }))
  }

  const buttonEnabled = !!fields.branche && !!fields.revenuePerMonth && !!fields.processPerMonth && !!fields.currentCostPerYear

  const defaultFormFields = {
    input_6: fields.branche,
    input_7: fields.revenuePerMonth,
    input_8: fields.processPerMonth,
    input_9: `€${fields.currentCostPerYear}`,
    input_10: percentage,
  }

  if (fields.revenuePerMonth) {
    defaultFormFields.input_7 = revenuePerMonthOptions.find(({ value }) => value === fields.revenuePerMonth).label
  }

  if (fields.processPerMonth) {
    defaultFormFields.input_8 = processPerMonthOptions.find(({ value }) => value === fields.processPerMonth).label
  }

  if (percentage) {
    defaultFormFields.input_10 = `${percentage.toString()}%`
  }

  return (
    <StyledCalculator>
      <Title>
        Berekeningsmodule
      </Title>
      <div className="pb-5">
        <div className="row">
          <div className="col-lg-7">
            <div className="mb-3 position-relative" style={{ zIndex: 3 }}>
              <InputSelect>
                <Select
                  classNamePrefix="select"
                  onChange={(event) => {
                    setFields({
                      branche: event.value,
                      revenuePerMonth: '',
                      processPerMonth: '',
                      currentCostPerYear: ''
                    })
                  }}
                  options={branches.map(branche => ({ label: branche.label, value: branche.label }))}
                  placeholder="Soort branche"
                />
              </InputSelect>
            </div>
            {fields.branche && (
              <>
                <div className="mb-3 position-relative" style={{ zIndex: 2 }}>
                  <InputSelect>
                    <Select
                      classNamePrefix="select"
                      onChange={(event) => {
                        setFields(prevFields => ({ ...prevFields, ...{ revenuePerMonth: event.value } }))
                      }}
                      options={revenuePerMonthOptions}
                      placeholder="Omzet p/ jaar"
                    />
                  </InputSelect>
                </div>
                <div className="mb-3 position-relative" style={{ zIndex: 1 }}>
                  <InputSelect>
                    <Select
                      classNamePrefix="select"
                      onChange={(event) => {
                        setFields(prevFields => ({ ...prevFields, ...{ processPerMonth: event.value } }))
                      }}
                      options={processPerMonthOptions}
                      placeholder="Aantal te verwerken facturen p/maand"
                    />
                  </InputSelect>
                </div>
                <div className="mb-3 position-relative">
                  {fields.currentCostPerYear && (
                    <Label>Kosten huidige administratie p/ jaar</Label>
                  )}
                  <InputMoneyWrapper>
                    <InputMoneyWrapperValuta filled={fields.currentCostPerYear}>
                      &euro;
                    </InputMoneyWrapperValuta>
                    <InputMoney
                      type="number"
                      placeholder="Kosten huidige administratie p/ jaar"
                      onChange={(event) => {
                        const { value } = event.target

                        setFields(prevFields => ({ ...prevFields, ...{ currentCostPerYear: convertToNumber(value) } }))
                      }}
                      value={fields.currentCostPerYear}
                      filled={fields.currentCostPerYear}
                    />
                  </InputMoneyWrapper>
                </div>
              </>
            )}

            <div className="mt-5">
              <ButtonDefault
                isCustom
                disabled={!buttonEnabled}
              >
                <button
                  disabled={!buttonEnabled}
                  type="button"
                  onClick={() => {
                    const newPercentage = figureOut()

                    setPercentage(newPercentage)
                  }}
                >
                  Start berekening
                </button>
              </ButtonDefault>
            </div>

            <Result percentage={percentage} costperYear={fields.currentCostPerYear} />

            <AnimatePresence>
              {(percentage !== false) && (
                <motion.div
                  start={{
                    x: -20,
                    opacity: 0
                  }}
                  animate={{
                    x: 0,
                    opacity: 1
                  }}
                  exit={{
                    x: -20,
                    opacity: 0
                  }}
                >
                  {fields.currentCostPerYear >= 500 && (
                    <ParseContent className="pb-4 pt-2" content="Dit is een indicatie. Krijg nu <strong>gratis en vrijblijvend</strong> een specifieke kostenberekening in je inbox!" />
                  )}
                  <GravityForm
                    id={5}
                    defaultFields={defaultFormFields}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="col-lg-5 mt-lg-0 mt-4">
            <ParseContent className="pb-4 pt-2" content={description} />
          </div>
        </div>
      </div>
    </StyledCalculator>
  )
}

const StyledResult = styled(motion.div)`
  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.l}; 
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.xm}; 
  }
`

const ResultPercentage = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.l};
  background-color: ${({ theme }) => theme.color.face.secondary};
  color: ${({ theme }) => theme.color.text.light};
  margin: 0 8px;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
`

const Result = ({ percentage = 0, costperYear }) => {
  let realPercentage = percentage

  console.log(costperYear);

  if (realPercentage < 5) {
    realPercentage = 5
  }

  return (
    <AnimatePresence>
      {(percentage !== false && realPercentage > 0) && (
        <StyledResult
          className="mt-5 font-weight-l"
          start={{
            y: -20,
            opacity: 0
          }}
          animate={{
            y: 0,
            opacity: 1
          }}
          exit={{
            y: -20,
            opacity: 0
          }}
        >
          {costperYear < 500 ? (
            <p>Het lijkt erop dat de berekening niet kan worden gemaakt, vul het onderstaande formulier in voor een op maat gemaakte offerte</p>
          ) : (
            <>
              {realPercentage === 0 && (
                <>
                  Kontado is net zo voordelig!
                </>
              )}
              {realPercentage > 0 && (
                <>
                  Kontado is
                  <ResultPercentage>
                    <CountUp
                      delay={.5}
                      start={0}
                      end={realPercentage}
                    />
                  </ResultPercentage>
                  % voordeliger
                </>
              )}
            </>
          )}
        </StyledResult>
      )}
    </AnimatePresence>
  )
}

export default Calculator